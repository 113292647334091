<template>
  <v-row>
    <!-- Start Single Service  -->
    <v-col xl="6" lg="6" md="6" sm="6" cols="12">
      <div class="single-service text-white mt--200 mt_sm--40">
        <div v-if="user === null" class="icon mb--50">
          <v-img
            transition="slide-x-transition"
            width="150"
            src="@/assets/images/brand/up.png"
            alt="Creative Agency"
          />
        </div>
        <div>
          <div v-if="!user">
            <h3 class="heading-title">
              <h1 class="head-title" data-animation="fadeInUp">
                <b style="color: #104788"><i>ελίν up </i></b>
                <b style="color: #104788">επιβράβευση</b>
              </h1>
            </h3>
            <p>
              Το <b><i>ελίν up </i></b>
              <b style="color: #104788">επιβράβευση</b> είναι το πιο ευέλικτο
              πρόγραμμα πιστότητας που συνδυάζει τη mobile τεχνολογία με
              μοναδικά προνόμια .
            </p>
            <div class="slider-btns">
              <Register notHeader="true" title="Εγγραφή" />
              <v-dialog v-model="dialogVisible" max-width="600">
                <template v-slot:activator="{ on, attrs }">
                  <a
                    data-animation="fadeInRight"
                    style="color: white !important"
                    v-bind="attrs"
                    v-on="on"
                    class="popup-video video-btn ani-btn button_beautify_video"
                    ><i class="fas fa-play"></i
                  ></a>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar>
                      <h4 class="modal-title">
                        <b style="color: #104788"><i> ελιν up </i></b>
                        <b style="color: #104788">επιβράβευση</b>
                      </h4>
                    </v-toolbar>
                    <v-card-text>
                      <!-- <video ref="videoRef" id="videoPlay" controls autoplay style="margin-top: 40px; width:100%"  >-->
                      <video
                        id="elinvidelement"
                        style="margin-top: 40px; width: 100%"
                        controls
                        autoplay
                      >
                        <source
                          src="@/assets/videos/elin_home.mp4"
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn text @click="dialogVisible = false"
                        >Κλείσιμο</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </div>
          </div>
          <div v-if="user">
            <h1 data-animation="fadeInUp" class="head-title mt--200 mt_sm--40">
              Καλώς ήρθες στο <b style="color: #104788"><i>ελίν up </i></b>
              <b style="color: #104788">επιβράβευση</b>
            </h1>
            <v-row class="mt--10">
              <v-col xl="1" lg="1" md="1" sm="1" cols="1"> </v-col>
              <v-col xl="3" lg="3" md="3" sm="3" cols="3">
                <div class="icon">
                  <!-- <img :src="`${user.avatar}`" width=150  /> -->
                  <img
                    src="@/assets/images/logo/user_elin_new.png"
                    width="80"
                  />
                </div>
              </v-col>
              <v-col xl="5" lg="5" md="5" sm="7" cols="5">
                <p
                  data-animation="fadeInUp"
                  style="
                    padding-right: 23px;
                    margin-bottom: 20px;
                    color: #104788;
                  "
                >
                  {{ user.full_name }}
                </p>
                <p
                  data-animation="fadeInUp"
                  style="
                    padding-right: 23px;
                    margin-bottom: 20px;
                    color: #104788;
                  "
                >
                  {{ user.total_points }} πόντοι
                </p>
              </v-col>
              <v-col xl="3" lg="3" md="3" sm="1" cols="3"> </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </v-col>
    <v-col xl="1" lg="1" md="1" sm="1" cols="12"> </v-col>
    <v-col xl="5" lg="5" md="5" sm="5" cols="12">
      <div class="mt--100 mb--50">
        <!-- <img src="@/assets/images/brand/kinito_elin.jpg" alt="Creative Agency" height="800"/> -->
        <a v-if="mainImage.url" :href="mainImage.url">
          <img
          :src="mainImage.src"
          alt="Creative Agency"
          height="700"
        />
        </a>
        <img
          v-else
          :src="mainImage.src"
          alt="Creative Agency"
          height="700"
        />
          <!-- <img
            :src="promoImage || '@/assets/images/brand/kinito_elin.jpg'"
            alt="Creative Agency"
            height="700"
          /> -->
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import Register from "@/views/Register";

export default {
  name: "user",
  components: {
    Register,
  },
  
  async created() {
    this.today = new Date()
    // this.$loading(true);
    // await this.getUser();
    // this.$loading(false);
  },
  watch: {
    dialogVisible(visible) {
      if (document.getElementById("elinvidelement")) {
        if (visible) {
          var vid = document.getElementById("elinvidelement");
          vid.play();
        } else {
          var vid = document.getElementById("elinvidelement");
          vid.pause();
        }
      }
    },
  },
  methods: {
    ...mapActions(["getUser"]),
  },
  computed:{
    ...mapGetters(["user"]),

    mainImage(){
      let image = { src: require('@/assets/images/brand/kinito_elin.jpg')}
      if(this.promoImage.name &&  this.today >= this.promoImage.from_date && this.today <= this.promoImage.to_date ){
        image.url = this.promoImage.url
        let promos = require.context('@/assets/images/promos/', false, /\.jpg$/)
        image.src = promos('./' + this.promoImage.name + ".jpg")
      }
      return image
    }
  },
  data() {
    return {
      dialogVisible: false,
      today: '',
      promoImage: {
        name: 'elinxmas310x588',
        from_date: new Date('2023-12-11 00:00:00'),
        to_date: new Date('2024-01-08 23:59:59'),
        url: 'https://tinyurl.com/2p9h2bmf'
        }
    };
  },
};
</script>

<style lang="scss">
.button_beautify_video {
  animation-delay: 1s;
  transition: background-color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.button_beautify_video:hover {
  background-color: #104788 !important;
}
.video-btn {
  background: #19b3e6;
  width: 60px;
  height: 60px;
  display: inline-block;
  text-align: center;
  line-height: 60px;
  border-radius: 50%;
  position: relative;
  margin-left: 16px;
}
.head-title {
  color: #134075;
  font-size: 65px;
  font-weight: 700;
  font-family: "Nunito", sans-serif;
  line-height: 1.3;
  margin-bottom: 32px;
}
@media only screen and (max-width: 600px) {
  .head-title {
    color: #134075;
    font-size: 28px !important;
    font-weight: 700;
    font-family: "Nunito", sans-serif;
    line-height: 1.3;
    margin-bottom: 32px;
  }
  .single-service {
    margin-top: 0px !important;
  }
}
</style>
